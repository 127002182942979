
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Footer from './Footer';
import { Box, styled } from '@mui/material';
import { useEffect } from 'react';
import NavBar from './NavBar';



function App() {


  const NoSelectBox = styled(Box)({
    userSelect: 'none',
    WebkitUserSelect: 'none', // Safari
    MozUserSelect: 'none', // Firefox
    MsUserSelect: 'none', // IE10+/Edge
  });

  useEffect(() => {
    // Disable right-click context menu
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    // Disable common copy/paste shortcuts
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && (e.key === 'c' || e.key === 'v' || e.key === 'a' || e.key === 'u')) {
        e.preventDefault();
      }
    };

        // Add event listeners to disable right-click and keyboard shortcuts
        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('keydown', handleKeyDown);
    
        // Clean up event listeners on component unmount
        return () => {
          document.removeEventListener('contextmenu', handleContextMenu);
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);
  return (
<NoSelectBox>
    <BrowserRouter>
      <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>

        <Box sx={{ flexGrow: 1 }}>
          <NavBar/>
        </Box>


        <Footer />
      </Box>
    </BrowserRouter>
    </NoSelectBox>
  );
}

export default App;
