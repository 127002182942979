import { AppBar, Box, Button, Drawer, IconButton, List, ListItem, Toolbar, Typography, styled } from '@mui/material'
import React, { useState } from 'react'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'
import { Link, Navigate, Route, Routes } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import './NavBar.css';
import Home from './Home'
import Questions from './Questions'
import Main from './Main'
import AboutUs from './AboutUs'
import ContactUs from './ContactUs'
import PrivacyPolicy from './PrivacyPolicy'
import TermsOfService from './TermsOfService'



const Buttons = styled(Button)({
    '&:hover': {
      color: '#E69B1A', // Change the color to your desired color
      textDecoration: 'none', // Optionally remove the underline
    },
  });


const NavBar = () => {

    const [drawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = (open) => {
        setDrawerOpen(open);
    }
    return (
        <>
            <AppBar  sx={{ position: 'static', top: 0 }} >
                <Toolbar sx={{ backgroundColor: 'black' }}>

                    {/* This code is for large screen */}

                    <IconButton size='large' edge='start' color='inherit' aria-label='logo' sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <LocalLibraryIcon />
                    </IconButton>
                    <Typography variant='h6' component='div' sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } , paddingRight:'0px'}}>TNPSC Prep</Typography>
                    <Box sx={{ display: { xs: 'none', md: 'flex' },paddingRight:'0px' }}>
                        {/* <Link to='/test-series'><Buttons sx={{ color: 'white' }}>Test series</Buttons></Link>
                        <Link to='/currentaffairs'><Buttons sx={{ color: 'white' }}>Previous year Questions</Buttons></Link> */}
                        <Link to='/' style={{textDecoration:'none'}}><Buttons sx={{ color: 'white' }}>Home</Buttons></Link>
                        <Link to='/tnpsc-subject-wise-online-test' style={{textDecoration:'none'}}><Buttons sx={{ color: 'white' }}>MCQ</Buttons></Link>
                        <Link to='/about-us' style={{textDecoration:'none'}}><Buttons sx={{ color: 'white' }}>About us</Buttons></Link>
                        <Link to='/contact-us' style={{textDecoration:'none'}}><Buttons sx={{ color: 'white' }}>Contanct Us</Buttons></Link>
                        
                    </Box>

                    {/* This code is for small screen */}


                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton color='inherit' edge='start' aria-label='logo' onClick={() => toggleDrawer(true)} >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <Typography variant='h6' component='div' sx={{ display: { xs: 'flex', md: 'none' } }}>TNPSC Prep</Typography>
                </Toolbar>
            </AppBar>

            {/* This is the drawer for smaller screen */}
            <Drawer anchor='top' open={drawerOpen} onClose={() => toggleDrawer(false)} sx={{ display: { xs: 'block', md: 'none' } }}>
                <List>
                    <ListItem sx={{ justifyContent: 'space-between' }}>
                        <Typography variant='h6' component='div'>TNPSC Prep</Typography>
                        <IconButton onClick={() => toggleDrawer(false)}>
                            <CloseIcon />
                        </IconButton>
                    </ListItem>
                    <ListItem>
                        <Link to='/' style={{textDecoration:'none'}}><Button sx={{ color: 'black' }} onClick={() => toggleDrawer(false)}>Home</Button></Link>
                    </ListItem>
                    <ListItem>
                        <Link to='/tnpsc-subject-wise-online-test' style={{textDecoration:'none'}}><Button sx={{ color: 'black' }} onClick={() => toggleDrawer(false)}>MCQ</Button></Link>
                    </ListItem>
                    <ListItem>
                        <Link to='/about-us' style={{textDecoration:'none'}}><Button sx={{ color: 'black' }} onClick={() => toggleDrawer(false)}>About us</Button></Link>
                    </ListItem>
                    <ListItem>
                        <Link to='/contact-us' style={{textDecoration:'none'}}><Button sx={{ color: 'black' }} onClick={() => toggleDrawer(false)}>contact us</Button></Link>
                    </ListItem>
                 </List>   
            </Drawer>

            {/* Routing the component */}
            <Routes>
                <Route path='/' element={<Home/>} />
                <Route path='/tnpsc-questions/:title/:topic' element={<Questions/>}/>
                <Route path='/*' element={<Navigate to='/' />} />
                <Route path='/tnpsc-subject-wise-online-test' element={<Main/>}/>
                <Route path='/about-us' element={<AboutUs/>}/>
                <Route path='/contact-us' element={<ContactUs/>}/>
                <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
                <Route path='/terms-of-service' element={<TermsOfService/>}/>
                <Route path='/tnpsc-free-mock-tests' element={<Questions/>}/>
            </Routes>
        </>
    )
}

export default NavBar