
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Radio, Select, TextField, Typography, styled } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import './SingleQuestion.css'
import config from './config/config'

  

const SingleQuestion = ({ questionObj, value, correct, total, setCorrects, setTotal, setMarkColour }) => {

    
    
    const apiUrl = config.apiUrl
    const [selectedValue, setSelectedValue] = useState('');
    const [color, setColor] = useState('');
    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [reportStatus, setReportStatus] = useState(false);
    const [emailValidation, setEmailValidation] = useState("Enter proper EmailId")
    const [selectedReason, setSelectedReason] = useState("");
    const [email, setEmail] = useState("");
    const [reasonValidation, setReasonValidation] = useState("Select One Reason")
    const [activateOthers, setActivateOthers] = useState(false)
    const [otherReason, setOtherReason] = useState('');
    const [result, setResult] = useState('')
    const [optionSelected, setOptionSelected] = useState(false)
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;



    useEffect(() => {
        // Load user's response from local storage when component mounts
        const storedResponse = localStorage.getItem(`response_${questionObj.question}`);
        if (storedResponse) {
            setSelectedValue(storedResponse);
            if (storedResponse === questionObj.answer) {
                setColor('#cfffc3');
                setSelectedAnswer(false);
            } else {
                setColor('#ffcbcd');
                setSelectedAnswer(true);
            }
        }
    },[questionObj.question]);

    const StyledRadio = styled(Radio)({
        color: 'black',
        '&.Mui-checked': {
            color: 'black'
        }
    })

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        localStorage.setItem(`response_${questionObj.question}`, event.target.value);
        setOptionSelected(true)//need to change
        let tempTotal = total+1;
        let tempCorrect;
        setTotal(tempTotal)

        if (questionObj.answer === event.target.value) {
            tempCorrect = correct+1;
            setCorrects(tempCorrect)
            setColor('#cfffc3')
            setSelectedAnswer(false)
            
        } else {
            tempCorrect = correct;
            setColor('#ffcbcd')
            setSelectedAnswer(true)
        }
            console.log(tempCorrect)
            console.log(tempTotal)
        if ((tempCorrect * 100 /tempTotal) > 90) {
            setMarkColour('#00FF00')
        }
        else if ((tempCorrect * 100 / tempTotal) > 80) {
            setMarkColour('#66FF00')
        }
        else if ((tempCorrect * 100 / tempTotal) > 70) {
            setMarkColour('#CCFF00')
        }
        else if ((tempCorrect * 100 /tempTotal) >60) {
            setMarkColour('#FFFF00')
        }
        else if ((tempCorrect * 100 /tempTotal) > 50) {
            setMarkColour('#FFCC00')
        }
        else if ((tempCorrect * 100 / total) > 40) {
            setMarkColour('#FF9900')
        }
        else if ((tempCorrect * 100 / total) > 30) {
            setMarkColour(' #FF6600')
        }
        else if ((tempCorrect * 100 / total) > 20) {
            setMarkColour('#FF3300')
        }
        else {
            setMarkColour('#FF0000')
        }


    }

    // to open the report dialog box
    const reportOpen = () => {
        setReportStatus(!reportStatus)
        setSelectedReason("")
        setEmail("")
        setOtherReason("")
        setActivateOthers(false)
    }

    const reportOpenbutton = () => {
        setReportStatus(!reportStatus)
        setSelectedReason("")
        setEmail("")
        setOtherReason("")
        setReasonValidation("Select One Reason")
        setEmailValidation("Enter proper EmailId")
        setResult("")
        setActivateOthers(false)
    }


    //for email validation
    const emailValidationFunction = (e) => {
        if (emailRegex.test(e.target.value)) {
            setEmailValidation("");
            setEmail(e.target.value);
        }
        else {
            setEmailValidation("Enter proper EmailId");
            setEmail(e.target.value);
        }
    }

    //to get value for reason
    const handleSelectChange = (e) => {
        setSelectedReason(e.target.value)
        setReasonValidation("")
        if (e.target.value === 'Others') {
            setActivateOthers(true)
        }
        else {
            setActivateOthers(false)
        }
    }

    //to get value of other reason
    const onOtherReasonChange = (e) => {
        console.log(e.target.value)
        setOtherReason(e.target.value)
    }
    //to send the value to backend
    const postData = async (e) => {
        e.preventDefault();
        const dataObject = {
            email: email,
            reason: selectedReason,
            description: otherReason,
            resolvedStatus: "not resolved",
            question: questionObj.question
        }
        const data = await axios.post(`${apiUrl}/report/insertreport`, dataObject);
        if (data !== null) {
            setResult("Report Submitted Successfully")
            setEmail('')
            setOtherReason('')
            setSelectedReason('')
        }
        else {
            setResult("Something Wrong")
        }

    }
    //to check whether the reason is selected or not

    return (
       
        <Paper elevation={3} sx={{ margin: '5px 5px', padding: '0px 10px', backgroundColor: color }}>
            
            <Typography variant='h6' component='div'><span>{value + 1}. </span>{questionObj.question}
            </Typography>
            
            <FormControlLabel value={questionObj.option1} control={<StyledRadio disabled={optionSelected} />} label={questionObj.option1} onChange={handleChange} checked={selectedValue === questionObj.option1} /> <br />
            <FormControlLabel value={questionObj.option2} control={<StyledRadio disabled={optionSelected} />} label={questionObj.option2} onChange={handleChange} checked={selectedValue === questionObj.option2} /> <br />
            {
                questionObj.option3 !== null && questionObj.option3 !== "" ? <><FormControlLabel value={questionObj.option3} control={<StyledRadio disabled={optionSelected} />} label={questionObj.option3} onChange={handleChange} checked={selectedValue === questionObj.option3} /> <br /></> : ""
            }
            {
                questionObj.option4 !== null && questionObj.option4 !== "" ? <><FormControlLabel value={questionObj.option4} control={<StyledRadio disabled={optionSelected} />} label={questionObj.option4} onChange={handleChange} checked={selectedValue === questionObj.option4} /> <br /></> : ""
            }
           
            <Grid container alignItems="center">
                {/* Left side content */}
                <Grid item xs={6}>
                    <Typography variant='h6' component='div' style={{ flexGrow: 1 }}>
                        {selectedAnswer ? <span>Ans: {questionObj.answer}</span> : ''}
                    </Typography>
                </Grid>

                {/* Right side content */}
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Button
                        variant='contained'
                        size='medium'
                        sx={{
                            width: '120px',
                            marginLeft: '0.7vw',
                            marginBottom: '1vw',
                            backgroundColor: 'black',
                            '&:hover': {
                                backgroundColor: 'gray',
                                color: 'black'
                            }
                        }}
                        onClick={reportOpenbutton}
                    >
                        Report
                    </Button>
                </Grid>
            </Grid>
                    
            <Dialog open={reportStatus}>
                <Box sx={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ display: 'inline-flex', flexGrow: 1 }}>Report Form</DialogTitle>
                    <IconButton onClick={reportOpenbutton}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent sx={{}}>
                    <Box sx={{ paddingBottom: '12px' }}>
                        <TextField
                            className='formText'
                            autoFocus
                            margin="dense"
                            label="Email"
                            type="email"
                            fullWidth
                            required
                            name="email"
                            //helperText={emailValidation}
                            value={email}
                            onChange={emailValidationFunction}
                        />
                        <Typography component='p' variant='h9' sx={{ color: "red" }}>{emailValidation}</Typography>
                    </Box>
                    <Box sx={{ paddingBottom: '10px' }}>
                        <FormControl fullWidth >
                            <InputLabel id="demo-simple-select-label" sx={{ zIndex: 0 }}>Reason</InputLabel>
                            <Select
                                value={selectedReason}
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                label='Reason'
                                onChange={handleSelectChange}
                                sx={{
                                    borderColor: 'black',
                                    zIndex: 0,
                                }}
                            >
                                <MenuItem value={null} disabled >Select</MenuItem>
                                <MenuItem value="Wrong Question">Wrong Question</MenuItem>
                                <MenuItem value="Wrong Answer">Wrong Answer</MenuItem>
                                <MenuItem value="Others">Others</MenuItem>
                            </Select>
                        </FormControl>

                        <Typography component='p' variant='h9' sx={{ color: "red" }}>{reasonValidation}</Typography>

                    </Box>
                    {
                        activateOthers ?
                            <Box>
                                <TextField
                                    className='formText'
                                    label="Description"
                                    multiline
                                    fullWidth
                                    rows={5}
                                    name="description"
                                    value={otherReason}
                                    onChange={onOtherReasonChange}
                                />
                                <Typography component='p' variant='h9' >{otherReason.length}/1000</Typography>
                            </Box> : ""
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={reportOpen} color="error">
                        Cancel
                    </Button>
                    <Button color="success" disabled={emailValidation === "" && reasonValidation === "" ? false : true} onClick={postData}>
                        Send
                    </Button>
                </DialogActions>

                <Box sx={{ paddingBottom: '15px' }}>
                    <Typography component='p' variant='h9' sx={{ color: "green", paddingLeft: '5px' }}>{result}</Typography>
                </Box>
            </Dialog>
        </Paper>
                   
    )
}

export default SingleQuestion