import { Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './SingleQuestion.css'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import config from './config/config';

const ContactUs = () => {

  const apiUrl = config.apiUrl
  const [result,setResult] = useState("")
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [emailValidation, setEmailValidation] = useState("Enter proper EmailId")
  const [nameValidation, setNameValidation] = useState("Enter atleast 3 character")
  const [descValidation, setDescValidation] = useState("Enter Atleast 20 characters")
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;


  //for email validation
  const emailValidationFunction = (e) => {
    const newData = e.target.value
    if (emailRegex.test(newData)) {
      setEmailValidation("");
      setEmail(newData);
    }
    else {
      setEmailValidation("Enter proper EmailId");
      setEmail(newData);
    }
  }

  // for Name validation
  const nameValidationFunc = (e) => {
    const newName = e.target.value;
  if (newName.length > 2) {
    setNameValidation("");
    setName(newName);
  } else {
    setName(newName);
    setNameValidation("Enter at least 3 characters");
  }
  }

  //for Description validation
  const descValidationFunc = (e) => {
    const newData = e.target.value;
    if(newData.length<=1000)
      {
    if (newData.length > 19) {
      setDescValidation("")
      setDescription(newData)
    } else {
      setDescription(newData)
      setDescValidation("Enter Atleast 20 characters")
    }
  }
  else{
    setDescValidation("Limit Reached")
  }
  }

  //for clearing the form
  const clear = ()=>{
    setEmail('')
    setName('')
    setDescription('')
    setEmailValidation("Enter proper EmailId")
    setNameValidation("Enter atleast 3 character")
    setDescValidation("Enter Atleast 20 characters")
  }

  //to send the data
  const send = async (e)=>{
    e.preventDefault();
    const dataObject = {
        email : email,
        name : name,
        description : description
    }
    const data = await axios.post(`${apiUrl}/contact/insert-data`,dataObject);
    if(data!==null)
        {
            setResult("Submitted Successfully")
            clear()
        }
        else{
            setResult("Something Wrong")
        }
  }

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when currentPage changes
  }, []);
  return (
    <>
      <Container maxWidth="sm" >
        <Helmet>
        <title>Contact us</title>
        <meta name="description" content="Get in touch with TNPSC Prep for any inquiries, support, or feedback. We are here to assist you with your preparation for TNPSC and TNUSRB exams through our free online practice tests and mock exams." />
        <meta name="keywords" content="tnpsc contact us, tnusrb contact us" />
        </Helmet>
        <Grid container xs={12} spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} sx={{ marginTop: '10px' }}>
            <Typography variant="h4" align="center" gutterBottom style={{ fontFamily: 'poppins', fontWeight: 500 }}>
              Reach Us
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{marginLeft:'25px'}}>
          <Typography variant="body1" align="center" gutterBottom style={{ fontFamily: 'poppins', fontWeight: 400 }}>
          Have a question, suggestion, or just want to get in touch? We'd love to hear from you! Connect with us with <span style={{fontWeight:'bold'}} >Mail Id : contactus@tnpscprep.in </span>or Use the form below to send us a message, and we'll get back to you as soon as possible.
            </Typography>
            <Paper  elevation={3} sx={{ width: '100', padding: '20px'}}>

              <Box sx={{ paddingBottom: '12px' }} >
                <TextField
                  className='formText'
                  autoFocus
                  margin="dense"
                  label="Name"
                  type="text"
                  fullWidth
                  required
                  name="name"
                  value={name}
                  onChange={nameValidationFunc}
                />
                <Typography component='p' variant='h9' sx={{ color: "red" }}>{nameValidation}</Typography>
              </Box>
              <Box sx={{ paddingBottom: '12px' }}>
                <TextField
                  className='formText'
                  margin="dense"
                  label="Email"
                  type="email"
                  fullWidth
                  required
                  name="email"
                  // helperText={emailValidation}
                  value={email}
                  onChange={emailValidationFunction}
                />
                <Typography component='p' variant='h9' sx={{ color: "red" }}>{emailValidation}</Typography>
              </Box>
              <Box>
                <TextField
                  className='formText'
                  label="Description"
                  multiline
                  fullWidth
                  rows={5}
                  name="description"
                  value={description}
                  onChange={descValidationFunc}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography component='p' variant='h9' sx={{ color: "red", display: 'inline' }}>
                    {descValidation}
                  </Typography>
                  <Typography component='p' variant='h9' sx={{ display: 'inline' }}>
                    {description.length}/1000
                  </Typography>
                </Box>

              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
              <Box sx={{ flexGrow: 1 }} >
                <Typography component='p' variant='h9' sx={{color:"green", paddingLeft:'5px'}}>{result}</Typography>
                </Box>
                <Button variant="contained" onClick={send} sx={{
                  marginRight: '10px', backgroundColor: 'green',
                  '&:hover': {
                    backgroundColor: '#50C878',
                    color: 'white'
                  }
                }} disabled={emailValidation === ''&&nameValidation === ''&&descValidation === ''?'':true} >
                  Send
                </Button>
                <Button variant="contained" onClick={clear} color="primary" sx={{
                  marginRight: '10px', backgroundColor: 'gray',
                  '&:hover': {
                    backgroundColor: 'darkgray',
                    color: 'black'
                  }
                }} >
                  Clear
                </Button>

              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default ContactUs