import { Box, Button, Card, CardActions, CardContent, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import img1 from './image/homeimg.jpeg'
import 'typeface-poppins';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Home = () => {


  
  const cards = [
    {
      title: 'Topic wise MCQ', description: 'Free topic-wise online tests based on the syllabus, for TNPSC Group 1, Group 2, 2A, and Group 4 aspirants.', url: "/tnpsc-subject-wise-online-test", buttonText :"Topic wise Test"
    },
    {
      title: 'Tnpsc mock', description: 'Free online mock tests based on the syllabus, for TNPSC Group 1, Group 2, 2A, and Group 4 aspirants.', url: "/tnpsc-free-mock-tests",buttonText :"Mock Test"
    },
    {
      title: 'Current Affairs', description: 'Free current affairs tests based on the syllabus, for TNPSC Group 1, Group 2, 2A, and Group 4 aspirants', url: "/tnpsc-current-affairs",buttonText :"Current affairs"
    },
  ]
  return (
    <Box sx={{ flexGrow: 1, paddingTop: 4, paddingBottom: 4, paddingLeft: 2, backgroundColor: '' }} >
      <Helmet>
        {/* Title */}
        <title>Free Online Test for TNPSC and TNUSRB - Mock Exams and Practice Questions</title>

        {/* Meta Description */}
        <meta name="description" content="Boost your TNPSC and TNUSRB SI exam preparation with our free mock tests and tnpsc online test. Get free subject-wise and topic-wise tests, plus current affairs quizzes, to ace TNPSC group 1, group 2, group 2a, group 4, and TNUSRB SI exams." />

        {/* Meta Keywords */}
        <meta name="keywords" content="TNPSC exam, Tamil Nadu Public Service Commission, TNPSC group 1 exams, TNPSC group 2 exams, TNPSC group 2a exams, TNPSC group 4 exams, TNPSC mock tests, Tnpsc mock test free, Tnpsc mock test group 1, Tnpsc mock test group 2, Tnpsc mock test group 2a, Tnpsc mock test group 4, TNPSC aspirants, TNPSC exam candidates, TNPSC exam preparation, TNPSC free online tests, TNPSC online mock tests, free online tnpsc group 4 test, free online test for tnpsc, free online tnpsc group 2 test, free online tnpsc group 4a test, free online tnpsc group 1 test, tnpsc free online test subject wise, tnpsc free online test topic wise, tnpsc free online test syallabus wise, TNPSC online test series, TNPSC online exam practice, TNPSC free test series, Free TNPSC exam preparation online, Free TNPSC general studies test, Tnpsc free general studies test, TNPSC current affairs online test free, TNPSC history online test free, TNPSC geography online test, Free TNPSC Indian polity mock test, TNPSC Indian economy practice test online, TNPSC science online quiz free, TNPSC group 1 free online test series, Free TNPSC group 2 online mock test, TNPSC group 3 online practice test free, TNPSC group 4 free model test online, TNPSC VAO online test free, Free TNPSC daily online test, Free TNPSC easy online test, TNPSC medium difficulty level online test free, Free TNPSC hard online mock test, TNPSC advanced level online practice test free, Best website for free TNPSC online tests, Where to find free TNPSC online practice tests, Free TNPSC online test series with instant results, Tips to score high in TNPSC online tests" />

        <link rel="canonical" href={window.location.origin} />
      </Helmet>
      <Container maxWidth="lg">
        <Grid container spacing={2} style={{ backgroundColor: '#F5F5F5' }}>
          <Grid item xs={12} md={6}>

            <Typography variant="h4" component="h1" gutterBottom style={{ fontFamily: 'poppins', fontWeight: 600 }}>
              Welcome to TNPSC Prep: Your Guide to TNPSC Exam Success
            </Typography>
            <Typography variant="body1" paragraph style={{ fontFamily: 'poppins', fontWeight: 400 }}>
              Get ready to do great in your TNPSC exams with TNPSC Prep! We offer lots of free online tests to help you prepare for TNPSC group 1, group 2, and group 4 exams.
            </Typography>
            <Typography variant="body1" paragraph style={{ fontFamily: 'poppins', fontWeight: 400 }}>
              Take our full test series to see how well you're doing overall or focus on specific subjects like general studies with our topic-wise online tests. Need to see how well you know everything? Our full syllabus tests have got you covered.
            </Typography>
            <Typography variant="body1" paragraph style={{ fontFamily: 'poppins', fontWeight: 400 }}>
              Our easy-to-use platform tells you right away how you're doing on each question, helping you learn and improve. Join us now and increase your chances of doing well in the TNPSC exams!
            </Typography>
            <Typography variant="body1" paragraph style={{ fontFamily: 'poppins', fontWeight: 400 }}>
              All Questions are taken from the SCERT books based on the tnpsc syllabus.
            </Typography>

          </Grid>
          <Grid item xs={12} md={6} sx={{ paddingRight: '3vw' }}>

            <img
              src={img1}
              alt="Image for main page"
              style={{ width: '100%', height: 'auto', display: 'block', maxHeight: '400px', maxWidth: '500px' }}
            />
          </Grid>
        </Grid>


        {/* Quiz Categories */}
        <Grid container spacing={2} style={{ marginTop: '10px', marginBottom: '10px' }}>
          <Grid item xs={12} sx={{ paddingBottom: '10px' }}>
            <Typography variant="h5" componenet="h2" gutterBottom sx={{ textAlign: 'center' }} style={{ fontFamily: 'poppins', fontWeight: 500 }}>
              Tnpsc MCQ
            </Typography>
          </Grid>

          <Grid container spacing={2} style={{ paddingLeft: 2 }}>
            {cards.map((card, index) => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                <Card sx={{ display: 'flex', flexDirection: 'column', minHeight: '100%', backgroundColor: '#d7ccc8' }}>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" component="h3" gutterBottom style={{ fontFamily: 'poppins', fontWeight: 600, position: 'relative' }}>
                      {card.title}{card.title === 'Current Affairs' ? <span style={{ position: 'absolute', right: 0, top: 0, color: 'red', fontWeight: 300, fontSize: '15px' }}><sup>*</sup>Coming Soon</span> : ''}
                    </Typography>
                  </CardContent>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="body1" paragraph style={{ fontFamily: 'poppins', fontWeight: 400 }}>
                      {card.description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {card.title === 'Current Affairs' ? (
                      <Button
                        variant='contained'
                        size='medium'
                        disabled={true}
                        sx={{
                          width: '200px',
                          marginLeft: '0.7vw',
                          marginBottom: '1vw',
                          backgroundColor: 'black',
                          '&:hover': {
                            backgroundColor: 'gray',
                            color: 'black'
                          }
                        }}
                      >
                        {card.buttonText}
                      </Button>
                    ) : (
                      <Link to={card.url} style={{ textDecoration: 'none' }}>
                        <Button
                          variant='contained'
                          size='medium'
                          sx={{
                            maxwidth: '200px',
                            marginLeft: '0.7vw',
                            marginBottom: '1vw',
                            backgroundColor: 'black',
                            '&:hover': {
                              backgroundColor: 'gray',
                              color: 'black'
                            }
                          }}
                        >
                          {card.buttonText}
                        </Button>
                      </Link>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>


        { /* Benefits */}
        <Grid container spacing={2} style={{ marginTop: '10px', marginBottom: '10px', backgroundColor: '#F5F5DC', borderRadius: "5px" }}>
          <Grid item xs={12} sx={{ paddingRight: '14px' }}>
            <Typography variant="h5" gutterBottom style={{ fontFamily: 'poppins', fontWeight: 500 }} align='center'>
              Benefits of Using Our Website
            </Typography>
            <Typography variant="body1" paragraph style={{ fontFamily: 'poppins', fontWeight: 400 }} align='center'>
              Our Site contains more than 14000+ question for practise. All Questions are taken from the SCERT books.<br />
              For TNPSC Group 1, 2, 2A, and 4 aspirants, it will help to gain strength in the General Studies topic to clear the exam and secure your dream job. It will test your knowledge of the SCERT books.
            </Typography>

            {/* Add more benefits content here */}
          </Grid>
        </Grid>


        {/* Syllabus Coverage */}
        <Grid container spacing={2} style={{ marginTop: '10px', marginBottom: '10px', backgroundColor: '#E6E6FA', borderRadius: "5px" }}>
          <Grid item xs={12} sm={6} md={4} lg={4} sx={{ paddingRight: '25px' }}>
            <Typography variant="h5" gutterBottom style={{ fontFamily: 'poppins', fontWeight: 500 }} align='center'>
              Syllabus Coverage
            </Typography>
            <Typography variant="body1" paragraph style={{ fontFamily: 'poppins', fontWeight: 400 }} align='center'>
              All the Questions are taken on the basis of the syllabus provided by Tnpsc. The sources of the questions are from School books.
            </Typography>
            {/* Add more syllabus coverage content here */}
          </Grid>


          {/* Stay Updated */}

          <Grid item xs={12} sm={6} md={4} lg={4} sx={{ paddingRight: '25px' }}>
            <Typography variant="h5" gutterBottom style={{ fontFamily: 'poppins', fontWeight: 500 }} align='center'>
              Future Updates
            </Typography>

            <Typography variant="body1" paragraph style={{ fontFamily: 'poppins', fontWeight: 400 }} >
              1. The Questions for History  Socio Political Movements in TamilNadu and Developement Administration in Tamilnadu is will be available soon.
            </Typography>
            {/* Add more stay updated content here */}
            <Typography variant="body1" paragraph style={{ fontFamily: 'poppins', fontWeight: 400 }}>
              2. Option to select two or more topic and get questions based on that.
            </Typography>
          </Grid>

          {/* Study Resources */}

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Typography variant="h5" gutterBottom style={{ fontFamily: 'poppins', fontWeight: 500 }} align='center'>
              Study Resources
            </Typography>
            <Typography variant="body1" paragraph style={{ fontFamily: 'poppins', fontWeight: 400 }} align='center'>
              Use SCERT book for study and attend the online test here, The questions are taken from the SCERT books only.
            </Typography>
            {/* Add more study resources content here */}
          </Grid>
        </Grid>


        {/* Footer */}

      </Container>
    </Box>
  )
}

export default Home