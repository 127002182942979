import { Typography } from '@mui/material'
import React from 'react'

const Error = ({data}) => {
  return (
    
        <Typography variant='h4'>{data}</Typography>
   
  )
}

export default Error