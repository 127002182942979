import {  Box, Pagination, PaginationItem, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import SingleQuestion from './SingleQuestion.js';
import Error from './Error.js';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import config from './config/config.js';

const apiUrls = config.apiUrl;
const Questions = () => {

  let param = useParams();
  
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);//need to change
  const [data, setData] = useState('');
  const [err, setErr] = useState(null);
  const [correct, setCorrects] = useState(0);
  const [total, setTotal] = useState(0)
  const [markColour, setMarkColour] = useState('');
  const [title, setTitle] = useState();
  const [metaDesc, setMetaDesc] = useState();
  const [metkeywords, setMetaKeywords] = useState()
  const [heading, setHeading] = useState()
  const [mainContent1, setMainContent1] = useState()
  const [mainContent2, setMainContent2] = useState()
  const [mainContent3, setMainContent3] = useState()
  const [cUrl,setcUrl] = useState()
 
  
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';

      // Show confirmation dialog
      const confirmationMessage = 'Are you sure you want to leave this page?';
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    

    // Add event listener for beforeunload event
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Clean up event listeners
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


  let question;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


  useEffect(() => {
    async function fetchData() {
      try {
        if (param.title != null) {
          setcUrl(`${window.location.origin}${window.location.pathname}`)
          setTitle(`${param.topic.replace(/-/g, ' ')} tnpsc questions - tnpsc topic wise online test`)
          setMetaDesc(`Free ${param.topic.replace(/-/g, ' ')} question for TNPSC and TNUSRB exams, Practise the mcq for group 4, group 2, group 2a and group 1. Enhance you skill to face the exam, All question are based on the syllabus of TNPSC`)
          setMetaKeywords(`${param.topic.replace(/-/g, ' ')}, TNPSC Exam Preparation, TNUSRB Exam Preparation, Mock Tests, Group 4 Exams, Group 2 Exams, Group 2A Exams, Group 1 Exams, MCQs, Syllabus-based Questions`)
          setHeading(`${param.topic.replace(/-/g, ' ').toLocaleUpperCase()} MCQ Questions`)
          console.log("hello this ", apiUrls + param.title + param.topic)
          question = await axios.get(`${apiUrls}/getquestions/${param.title}/${param.topic}`);
          
          
        }
        else {
          setcUrl(`${window.location.origin}${window.location.pathname}`)
          setTitle("Free Full Mock Tests for TNPSC and TNUSRB Exams-TNPSC full test online")
          setMetaDesc("Free full test online for TNPSC and TNUSRB exams, Practise the full mock test for group 4, group 2, group 2a and group 1. Enhance you skill to face the exam, All question are based on the syllabus of TNPSC")
          setMetaKeywords(`TNPSC full mock tests, TNUSRB full mock tests, TNPSC group 1 full test, TNPSC group 2 full test,TNPSC group 2a full test, TNPSC group 4 full test, TNUSRB full test series, Free TNPSC full test online, TNUSRB full mock tests, TNPSC exam preparation, TNUSRB exam preparation`)
          setHeading("Free Mock Tests for TNPSC Group 1, Group 2, Group 2A, Group 4, and TNUSRB SI Exam")
          setMainContent1("Our free online full mock test platform offers comprehensive preparation for TNPSC and TNUSRB exams. Randomly selected questions cover all topics, simulating real exam conditions. Users can refresh the page for a new set of questions. With coverage for Group 1, Group 2, Group 2A, Group 4, and TNUSRB SI exams, aspirants can effectively prepare and improve their chances of success.")
          setMainContent2("If you want new questions, just refresh the page. This will give you a whole new set of questions to answer and have fun with!")
          setMainContent3("If you find any problems during the test, you can tell us by clicking the report button, and we'll fix them quickly. This helps make sure the test is right and everyone has fun.")
          question = await axios.get(`${apiUrls}/random/getQuestions`);

        }
        setData(question.data)
      } catch (error) {
        if (error.response) {
          setErr(error.response.data.errorMsg);
        }
        else {
          setErr("something went wrong")
        }
      }
    }
    fetchData();
    localStorage.clear()

  }, [])


  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when currentPage changes
  }, [currentPage]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page)
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem)
  
  


  return (
    <Box>
      {/* <Box sx={{position:'sticky',top:'0',backgroundColor:'yellow', width:'10vh' , height:'8vh', zIndex:1}}>{correct}/{total}</Box> */}
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDesc} />
        <meta name="keywords" content={metkeywords} />
        <link rel="canonical" href={cUrl} />
      </Helmet>

      <Paper
        sx={{
          position: 'sticky',
          top: 0,
          float: 'right',
          backgroundColor: markColour,
          width: isSmallScreen ? '10vh' : '18vh', // Adjust the values as needed
          height: isSmallScreen ? '8vh' : '12vh', // Adjust the values as needed
          zIndex: 1,
          display: total>0?'flex':'none',
          alignItems: 'center',
          justifyContent: 'center',
          
        }}
        elevation={3}
        
      >

        <Typography style={{ fontSize: isSmallScreen ? '2vh' : '3vh' }}>{correct}/{total}</Typography>
      </Paper>
      {
        err !== null ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh', minWidth: '80vw' }}>
          <Error data={err} /> </Box> : data === '' ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
            <CircularProgress /></Box> : <div style={{ paddingBottom: '50px', paddingLeft: "3vw", paddingRight: '3vw' }} >
          <div style={{ paddingBottom: '50px' }}>
            <Box sx={{ paddingLeft: "1vw", paddingRight: '3vw', marginBottom: '10px' }} display={currentPage===1?"":"none"}>
              <Typography variant="h4" component="h1" gutterBottom style={{ fontFamily: 'Poppins', fontWeight: 500, marginTop: "10px" }}>
                {heading}
              </Typography>

              <Typography variant="body1" paragraph style={{ fontFamily: 'Poppins', fontWeight: 400 }}>
                {mainContent1}
              </Typography>
              <Typography variant="body1" paragraph style={{ fontFamily: 'Poppins', fontWeight: 400 }}>
              {mainContent2}
              </Typography>
              <Typography variant="body1" paragraph style={{ fontFamily: 'Poppins', fontWeight: 400 }}>
              {mainContent3}
              </Typography>


              <Typography variant="h5" component='h2' style={{ fontFamily: 'Poppins', fontWeight: 600, textAlign: 'center' }}>Questions</Typography>
            </Box>
            {currentItems.map((questionObj, index) => (

              <SingleQuestion key={index + (9 * (currentPage - 1))} questionObj={questionObj} value={index + (9 * (currentPage - 1))} setCorrects={setCorrects} setTotal={setTotal} correct={correct} total={total} setMarkColour={setMarkColour} />

            ))}</div>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: 'white' }}>
            <Pagination
              count={Math.ceil(data.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              siblingCount={1}
              boundaryCount={1}
              defaultPage={1}
              size='large'
              shape='rounded'
              sx={{
                display: 'flex',
                justifyContent: 'center',
               
              }}
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  sx={{
                    '@media (max-width: 600px)': {
                      minWidth: '30px',
                      height: '30px',
                      fontSize: '4vw',
                    },
                  }}
                />
              )}
            />

          </Box>
        </div>
      }
    </Box>

    //position:'fixed', bottom:38,
  )
}


export default Questions